<template>
    <td class="center">{{ numbers.join(', ') }}</td>
    <td>
        <strong>Материал:</strong> {{ getPropertyLabel('material') }}<br/>
        <strong>Тип фасада:</strong> {{ getPropertyLabel('type') }}
    </td>
    <td>
        <strong>Фрезеровка:</strong> {{ facade.milling ? facade.milling : 'нет' }}<br/>
        <strong>Фреза по краю:</strong> {{ getPropertyLabel('edgeMilling') }}
    </td>
    <td>
        <strong>Тип:</strong> {{ getPropertyLabel('coatingType') }}<br/>
        <strong>Цвет:</strong> {{ facade.color }}
    </td>
    <td>
        <strong>Высота:</strong> {{ facade.height }} мм<br/>
        <strong>Ширина:</strong> {{ facade.width }} мм
    </td>
    <td class="center">
        {{ square }}
    </td>
    <td class="center">{{ numbers.length }}</td>
    <td class="print--node">{{ facade.note }}</td>
</template>
<script>
import FacadeDataMixin from "@/mixins/facadeDataMixin";

export default {
    name: 'FacadePrint',
    props: ['facade', 'numbers'],
    mixins: [FacadeDataMixin],
}
</script>