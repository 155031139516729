<template>
    <a-row>
        <a-col
            v-for="stage in stages"
            :key="stage.id"
            :span="span"
            :xs="{ span: 24 }"
            :sm="{ span: stages.length === 1 ? 24 : 12 }"
            :md="{ span: stages.length === 1 ? 24 : stages.length === 2 ? 12 : 8 }"
            :lg="{ span: Math.floor(24 / stages.length) }"
        >
            <a-collapse v-model:activeKey="activeKey[stage.id]" class="m-10">
                <a-collapse-panel :header="stage.name" :key="1">
                    <OrderList :stage="stage" />
                </a-collapse-panel>
            </a-collapse>
        </a-col>
    </a-row>
    <a-alert v-if="!stages.length" message="Список заказов пуст" type="error" />
</template>
<script>
import lodash from "lodash"
import OrderList from "@/components/order/List.vue";

export default {
    name: 'StageList',
    components: { OrderList },
    data() {
        return {
            activeKey: []
        }
    },
    computed: {
        stages() {
            return this.$store.getters["order/getStagesWithOrders"]
        },
        span() {
            return Math.floor(24 / this.stages.length)
        }
    },
    mounted: function () {
        this.activeKey = lodash.reduce(this.stages, function (result, stage) {
            result[stage.id] = ['1']
            return result
        }, {})
    },
}
</script>
<style scoped>
.m-10 {
    margin: 10px;
}
</style>