<template>
    <div v-if="order" class="m-20">
        <div v-if="isLoading" class="p-20">
            <a-card :title="title">
                <template #extra>
                    <div>
                        <a-tooltip
                            v-if="userIsManager()"
                            title="Напечатать заказ"
                            placement="bottomRight"
                            :color="'#108ee9'"
                        >
                            <a-button
                                @click="$refs.orderPrint.print()"
                            >
                                <template #icon>
                                    <PrinterOutlined />
                                </template>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip
                            v-if="userIsManager()"
                            title="Напечатать стикеры"
                            placement="bottomRight"
                            :color="'#108ee9'"
                        >
                            <a-button
                                @click="printStickers"
                            >
                                <template #icon>
                                    <ProfileOutlined />
                                </template>
                            </a-button>
                        </a-tooltip>
                        <a-tooltip title="Изменить" placement="bottomRight" :color="'#108ee9'"
                                   v-if="userIsManager() && creationStage">
                            <a-button
                                @click="$refs.OrderForm.open(order)"
                            >
                                <template #icon>
                                    <EditOutlined/>
                                </template>
                            </a-button>
                        </a-tooltip>
                    </div>
                </template>
                <a-row justify="space-between">
                    <a-col>
                        <div>{{ client }}</div>
                        <div>{{ deadline }}</div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-button
                        class="m-20"
                        type="primary"
                        v-if="userIsManager() && creationStage"
                        @click="$refs.FacadeForm.open()"
                    >
                        Добавить фасад
                    </a-button>
                    <FacadeList
                        :facades="order.facades"
                        :creationStage="creationStage"
                        :order-id="order.id"
                        @edit-facade="$refs.FacadeForm.open($event, true)"
                        @copy-facade="$refs.FacadeForm.open($event)"
                        @delete-facade="deleteFacade($event)"
                        @restore-facade="deleteFacade($event, false)"
                    />
                    <FacadeForm ref="FacadeForm" :orderId="order.id"/>
                </a-row>
                <div v-if="creationStage">
                    <a-button v-if="userIsManager() && order.facades.length > 0" type="primary" @click="sendToWork"
                              :loading="loading"
                              class="m-20">
                        Отправить в работу
                    </a-button>
                </div>
            </a-card>
            <OrderForm ref="OrderForm"/>
            <div class="hidden">
                <OrderPrint
                    :order="order"
                    ref="orderPrint"
                />
            </div>
            <div class="hidden">
                <div ref="printStickers">
                    <PrintSticker
                        v-for="facade in order.facades"
                        :facade="facade"
                        :order-id="order.id"
                    />
                </div>
            </div>
        </div>
        <a-row v-else type="flex" justify="space-around" align="middle" class="spin">
            <a-spin size="large"/>
        </a-row>
    </div>
    <a-alert v-else message="Нет данных по заказу" type="error"/>
</template>
<script>
import OrderItemMixin from "@/mixins/orderItemMixin"
import UserMixin from "@/mixins/userMixin"
import FacadeList from "@/components/order/facade/List.vue";
import FacadeForm from "@/components/order/facade/Form.vue";
import OrderForm from "@/components/order/Form.vue";
import OrderPrint from "@/components/order/Print.vue";
import PrintSticker from "@/components/order/facade/PrintSticker.vue";
import { Modal } from "ant-design-vue";
import { createVNode } from "vue";
import {
    PrinterOutlined,
    ProfileOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    RedoOutlined
} from "@ant-design/icons-vue";
import html2pdf from "html2pdf.js";

export default {
    name: 'OrderItem',
    data() {
        return {
            isLoading: false,
            loading: false
        }
    },
    mixins: [OrderItemMixin, UserMixin],
    components: {
        OrderPrint,
        PrintSticker,
        OrderForm,
        RedoOutlined,
        EditOutlined,
        PrinterOutlined,
        ProfileOutlined,
        CopyOutlined,
        DeleteOutlined,
        FacadeForm,
        FacadeList,
    },
    computed: {
        order() {
            return this.getOrder()
        },
        creationStage() {
            return !Array.isArray(this.order.facades)
                || this.order.facades.length === 0
                || this.order.facades.find(facade => facade.stageId === 1)
        },
    },
    methods: {
        getOrder() {
            return this.$store.getters['order/getOrder'](this.$route.params.id)
        },
        sendToWork: async function () {
            const order = this.getOrder()
            const result = await this.sendData('/orders/send-to-work/', {
                id: order.id,
                stageId: order.stages[0].id
            })
            if (result.data.length) {
                await this.setOrder()
            }
        },
        sendData: async function (url, data) {
            this.loading = true
            return await this.axios.post(url, data)
        },
        setOrder: async function () {
            await this.$store.dispatch('order/setOrders', false)
            this.loading = false
        },
        deleteFacade(facade, value = true) {
            Modal.confirm({
                title:
                    value
                        ? 'Вы уверены в том, что хотите удалить фасад?'
                        : 'Вы уверены в том, что хотите восстановить фасад?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: 'Да',
                okType: 'danger',
                cancelText: 'Отмена',
                onOk: async () => {
                    try {
                        await this.axios.post(
                            '/facades/delete',
                            {
                                id: facade.id,
                                deleted: value
                            }
                        );
                        return this.$store.dispatch('order/setOrder', { id: this.order.id, repeat: false })
                    } catch {
                        return console.log('Ошибка удаления!');
                    }
                },
            });
        },
        printStickers() {
            html2pdf(this.$refs.printStickers, {
                margin: [1, 10, 1, 10],
                filename: `stickers-${this.order.id}.pdf`,
                html2canvas: { scale: 4 },
                pagebreak: { after: '.pagebreakAfter' },
            });
        },
    },
    mounted: function () {
        this.$store.dispatch('facadeData/setFacadeData').then(() => {
            this.isLoading = true
            this.$store.dispatch('order/setOrder', { id: this.order.id })
        })
    },
}
</script>
<style scoped>
.m-20 {
    margin: 20px;
}
</style>