<template>
    <a-modal
        v-model:visible="visible"
        title="Создать заказ"
        :confirm-loading="confirmLoading"
        ok-text="Сохранить"
        cancel-text="Отменить"
        @ok="clickSave"
        width="90%"
        style="max-width: 830px"
    >
        <div v-if="isLoadingClients" class="p-20">
            <a-form
                :model="formState"
                name="create-order"
                layout="horizontal"
                ref="formRef"
            >
                <a-row type="flex" justify="space-around">
                    <a-col style="display: flex">
                        <a-form-item
                            label="Заказчик"
                            name="client"
                            :rules="[{ required: true, message: 'Выберите заказчика!' }]"
                        >
                            <a-select
                                v-model:value="formState.client"
                                show-search
                                placeholder="Выберите заказчика"
                                style="width: 300px"
                                :options="clients"
                                :filter-option="filterOption"
                                :notFoundContent="'Заказчик не найден'"
                            ></a-select>
                        </a-form-item>
                        <div>
                            <a-button shape="default" @click="$refs.ClientForm.open()">
                                <template #icon>
                                    <PlusOutlined />
                                </template>
                            </a-button>
                            <a-button :disabled="!formState.client" shape="default" @click="$refs.ClientForm.open(currentClient())">
                                <template #icon>
                                    <EditOutlined />
                                </template>
                            </a-button>
                        </div>
                        <ClientForm ref="ClientForm" @create="createClient" @update="updateClient" />
                    </a-col>
                    <a-col>
                        <a-form-item
                            label="Дата завершения"
                            name="deadline"
                            :rules="[{ required: true, message: 'Введите дату завершения!' }]"
                        >
                            <a-date-picker
                                v-model:value="formState.deadline"
                                :locale="locale"
                                :allowClear="false"
                                :format="dateFormat"
                            />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row type="flex" justify="space-around" class="switch-row">
                    <a-col
                        v-for="stage in formState.stages"
                        :xs="{ span: 24 }"
                        :sm="{ span: 12 }"
                        :md="{ span: 8 }"
                    >
                        <a-form-item :label="stage.name" name="stages">
                            <a-switch v-model:checked="stage.checked"/>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <a-row v-else type="flex" justify="space-around" align="middle" class="spin">
            <a-spin size="large"/>
        </a-row>
    </a-modal>
</template>
<script>
import dayjs from "dayjs";
import locale from "ant-design-vue/es/date-picker/locale/ru_RU";
import lodash from "lodash";
import {PlusOutlined, EditOutlined} from "@ant-design/icons-vue";
import ClientForm from "@/components/order/ClientForm.vue";

export default {
    name: 'OrderForm',
    components: {ClientForm, PlusOutlined, EditOutlined},
    data() {
        return {
            visible: false,
            confirmLoading: false,
            isLoadingClients: false,
            clients: [],
            formState: {},
            dateFormat: 'DD-MM-YYYY',
            orderId: null,
        }
    },
    methods: {
        open(order) {
            this.visible = true
            this.getClients()
            if (order) {
                this.formState = {
                    client: order.clientId,
                    deadline: dayjs(order.deadline),
                }
                this.getSwitchedStage(order)
                this.orderId = order.id
            } else {
                this.formState = {
                    client: '',
                    deadline: dayjs(),
                }
                this.getSwitchedStage()
                this.orderId = null
            }
        },
        clickSave() {
            this.confirmLoading = true
            this.$refs.formRef.validateFields()
                .then(() => this.saveOrder())
                .catch(() => this.confirmLoading = false)
        },
        saveOrder: async function () {
            const orderData = {
                clientId: this.formState.client,
                deadline: this.formState.deadline.unix() * 1000,
                stageIds: lodash.reduce(this.formState.stages, function (stageIds, stage) {
                    if (stage.checked) {
                        stageIds.push(stage.id)
                    }
                    return stageIds
                }, [])
            }
            if (this.orderId) {
                orderData.id = this.orderId
            }
            const response = await this.axios.post(this.orderId ? '/orders/update' : '/orders/create', orderData)
            if (response.data) {
                await this.$store.dispatch('order/setOrders', false)
                this.visible = false
                this.confirmLoading = false
                if (response.data.id) {
                    this.$router.push(`/orders/${response.data.id}`)
                }
            }
        },
        getClients() {
            this.axios.get('/clients').then(clients => {
                this.clients = clients.data.map(client => ({
                    value: client.id,
                    label: client.name,
                }))
                this.isLoadingClients = true
            })
        },
        currentClient() {
            return lodash.find(this.clients, {value: this.formState.client})
        },
        createClient(client) {
            this.clients.push(client)
            this.formState.client = client.value
        },
        updateClient(client) {
            this.currentClient().label = client.label
        },
        getSwitchedStage(order) {
            this.formState.stages = this.$store.getters['order/getWorkingStages'].map(stage => {
                return {
                    ...stage,
                    checked: (!order || !!lodash.find(order.stages, {id: stage.id}))
                }
            })
        },
        filterOption(input, option) {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
    },
    computed: {
        locale() {
            return locale
        },
    },
}
</script>
<style>
.switch-row {
    text-align: right;
}
.switch-row label {
    padding-left: 15px;
}
</style>