import lodash from "lodash";
import axios from "axios";

export const facadeDataModule = {
    state: () => ({
        facadeData: {}
    }),
    mutations: {
        setFacadeData(state, facadeData) {
            state.facadeData = facadeData
        }
    },
    actions: {
        async setFacadeData({ commit, dispatch }) {
            const facadeData = await axios.get('/facades/get-form-data')
            commit('setFacadeData', lodash.reduce(
                facadeData.data,
                function(result, value, key) {
                    result[key] = value.map(item => ({
                        value: item.id,
                        label: item.name,
                    }))
                    return result
                }, {})
            )
        }
    },
    getters: {
        getFacadeData: state => {
            return state.facadeData
        },
    },
    namespaced: true
}