<template>
    <tr :class="{ deleted: facade.deleted, blur: blurForCurrentUser, marked }" ref="thisFacade">
        <td class="relative center"><span>№ фасада</span>
            <div class="number">
                <a-badge v-if="inWork" color="green" class="in-work"/>
                {{ facade.facadeId }}
            </div>
        </td>
        <td class="stage"><span>Стадия</span>{{ facade.stage.name }}</td>
        <td><span>Парамерты изготовления</span>
            Материал: {{ getPropertyLabel('material') }}<br/>
            Тип фасада: {{ getPropertyLabel('type') }}<br/>
            Фрезеровка: {{ facade.milling ? facade.milling : 'нет' }}<br/>
            Фреза по краю: {{ getPropertyLabel('edgeMilling') }}
        </td>
        <td><span>Покрытие</span>
            Тип: {{ getPropertyLabel('coatingType') }}<br/>
            Цвет: {{ facade.color }}
        </td>
        <td><span>Размеры</span>
            Высота: {{ facade.height }} мм<br/>
            Ширина: {{ facade.width }} мм<br/>
            Площадь: {{ square }} м<sup>2</sup>
        </td>
        <template v-if="userIsManager()">
            <td class="center"><span>Цена</span>{{ facade.price }}</td>
            <td class="center"><span>Стоимость</span>{{ Math.ceil(square * facade.price * 100) / 100 }}</td>
        </template>
        <td style="white-space: pre-line"><span>Примечание</span>{{ facade.note }}</td>
        <td><span>Действия</span>
            <div v-if="userIsWorker()">
                <a-button
                    v-if="inWorkForCurrentUser"
                    type="primary"
                    @click="workCompleted"
                    class="green m-20"
                >
                    Завершить
                </a-button>
                <a-button
                    v-if="canTakeToWork"
                    type="primary"
                    @click="takeToWork"
                    class="m-20"
                >
                    В работу
                </a-button>
            </div>
            <div v-if="userIsManager()">
                <a-tooltip v-if="!this.inWork" title="Изменить" placement="bottomRight" :color="'#108ee9'">
                    <a-button
                        @click="$emit('edit-facade', facade)"
                    >
                        <template #icon>
                            <EditOutlined/>
                        </template>
                    </a-button>
                </a-tooltip>
                <a-tooltip v-if="this.creationStage" title="Скопировать" placement="bottomLeft" :color="'#108ee9'">
                    <a-button
                        @click="$emit('copy-facade', facade)"
                    >
                        <template #icon>
                            <CopyOutlined/>
                        </template>
                    </a-button>
                </a-tooltip>
                <a-tooltip v-if="facade.deleted" title="Восстановить" placement="bottomLeft" :color="'#108ee9'">
                    <a-button
                        @click="$emit('restore-facade', facade)"
                    >
                        <template #icon>
                            <RedoOutlined/>
                        </template>
                    </a-button>
                </a-tooltip>
                <a-tooltip v-if="!facade.deleted && !this.inWork" title="Удалить" placement="bottomLeft" :color="'#108ee9'">
                    <a-button
                        @click="$emit('delete-facade', facade)"
                    >
                        <template #icon>
                            <DeleteOutlined/>
                        </template>
                    </a-button>
                </a-tooltip>
            </div>
        </td>
    </tr>
    <tr v-if="userIsManager()">
        <td colspan="100%">
            <StageChangeList :stageChanges="facade.stageChanges"/>
        </td>
    </tr>
</template>
<script>
import FacadeDataMixin from "@/mixins/facadeDataMixin";
import UserMixin from "@/mixins/userMixin";
import FacadeForm from "@/components/order/facade/Form.vue";
import {
    EditOutlined,
    CopyOutlined,
    DeleteOutlined,
    RedoOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons-vue";
import StageChangeList from "@/components/order/facade/stageChange/List.vue";
import lodash from "lodash";
import { message, Modal } from "ant-design-vue";
import { createVNode, h } from "vue";

export default {
    name: 'FacadeItem',
    components: { StageChangeList, EditOutlined, CopyOutlined, DeleteOutlined, RedoOutlined, FacadeForm },
    props: ['facade', 'orderId', 'creationStage'],
    emits: ['editFacade', 'copyFacade', 'deleteFacade', 'restoreFacade'],
    mixins: [FacadeDataMixin, UserMixin],
    computed: {
        inWork() {
            return this.facade.stage.working && this.lastStageChanges.closedAt === null
        },
        lastStageChanges() {
            return lodash.last(this.facade.stageChanges)
        },
        inWorkForCurrentUser() {
            return this.$store.getters['order/getCurrentFacadeStageChange'].facadeId === this.facade.id
        },
        canTakeToWork() {
            return !this.facade.deleted && !this.inWork && this.userCanTakeToWork(this.facade.stageId)
        },
        blurForCurrentUser() {
            return this.userIsWorker() && !this.$store.getters['user/hasStage'](this.facade.stageId)
        },
        marked() {
            return this.$route.query.facade && Number(this.$route.query.facade) === this.facade.facadeId
        },
    },
    methods: {
        takeToWork() {
            Modal.confirm({
                title: `Вы уверены в том, что хотите взять а работу фасад № ${this.facade.id}?`,
                icon: createVNode(ExclamationCircleOutlined),
                content: h('div', {}, [
                    `Стадия: `,
                    h('strong', {}, `${this.facade.stage.name}`)
                ]),
                okText: 'Да',
                cancelText: 'Отмена',
                onOk: async () => {
                    await this.sendData('/facades/take-to-work/', {
                        id: this.facade.id,
                        stageId: this.facade.stageId,
                        preStageId: this.lastStageChanges.stageId,
                    })
                },
            });
        },
        workCompleted() {
            Modal.confirm({
                title: `Вы уверены в том, что хотите завершить а работу с фасадом № ${this.facade.id}?`,
                icon: createVNode(ExclamationCircleOutlined),
                okText: 'Да',
                cancelText: 'Отмена',
                onOk: async () => {
                    await this.sendData('/facades/work-completed/', {
                        id: this.facade.id,
                        stageId: this.facade.stageId,
                        nextStageId: this.$store.getters['order/getNextStage'](this.orderId, this.facade.stageId).id,
                    })
                },
            });
        },
        sendData: async function (url, data) {
            const result = await this.axios.post(url, data)
            if (result.data.message) {
                message.error(result.data.message);
            }
            await this.$store.dispatch('order/setOrders', false)
        },
    },
    mounted() {
        if (this.marked) {
            this.$refs['thisFacade'].scrollIntoView({ behavior: 'smooth'})
        }
    },
}
</script>
<style scoped>
@media (max-width: 812px) {
    td {
        margin: 0 -1px -1px 0;
        padding-top: 35px;
        position: relative;
        width: 50%;
    }

    td > span {
        display: block;
    }
}

.deleted {
    text-decoration: line-through;
}

.in-work {
    display: block;
    position: absolute;
    top: 2px;
    right: -1px;
}
</style>