<template>
    <a-card v-if="facades.length" class="show-all">
        <a-switch v-model:checked="showAll"/> показать все фасады
    </a-card>
    <table v-if="facades.length" class="table">
        <thead>
        <tr>
            <th>№</th>
            <th>Стадия</th>
            <th>Парамерты</th>
            <th>Покрытие</th>
            <th>Размеры</th>
            <template v-if="userIsManager()">
                <th>Цена</th>
                <th>Стоимость</th>
            </template>
            <th>Примечание</th>
            <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <FacadeItem
            v-for="facade in displayedFacades"
            :facade="facade"
            :key="facade.id"
            :order-id="orderId"
            :creationStage="creationStage"
            @edit-facade="$emit('edit-facade', $event)"
            @copy-facade="$emit('copy-facade', $event)"
            @delete-facade="$emit('delete-facade', $event)"
            @restore-facade="$emit('restore-facade', $event)"
        />
        <tr v-if="userIsManager()" style="background: none">
            <td colspan="5" style="border: none"></td>
            <td>Итого:</td>
            <td class="center"><strong>{{ total }}</strong></td>
        </tr>
        </tbody>
    </table>
</template>
<script>
import FacadeItem from "@/components/order/facade/Item.vue";
import UserMixin from "@/mixins/userMixin";
import lodash from "lodash";

export default {
    name: 'FacadeList',
    data() {
        return {
            showAll: false
        }
    },
    props: ['facades', 'orderId', 'creationStage'],
    mixins: [UserMixin],
    emits: ['editFacade', 'copyFacade', 'deleteFacade', 'restoreFacade'],
    components: {FacadeItem},
    computed: {
        total() {
            return lodash.reduce(this.facades, function (total, facade) {
                if (!facade.deleted) {
                    const square = Math.ceil(facade.height * facade.width / 1000) / 1000
                    total += Math.ceil(square * facade.price * 100) / 100
                }
                return Math.ceil(total * 100) / 100
            }, 0)
        },
        displayedFacades() {
            return this.facades.filter(facade => {
                return this.showAll || !this.$route.query.stage || facade.stageId === Number(this.$route.query.stage)
            })
        },
    }
}
</script>
<style scoped>
@media (max-width: 768px) {
    table thead {
        display: none;
    }

    table > tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
}
</style>