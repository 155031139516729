import dayjs from "dayjs";
import lodash from "lodash";

export default {
    computed: {
        title() {
            return 'Заказ № ' + this.order.id
                + ' от ' + dayjs(this.order.createdAt).format('DD.MM.YY')
        },
        deadline() {
            return 'Крайний срок: ' + dayjs(this.order.deadline).format('DD.MM.YY')
        },
        client() {
            return 'Заказчик: ' + this.order.client.name
        },
        calculatedData() {
            const currentFacadeStageChange = this.$store.getters['order/getCurrentFacadeStageChange']
            const data = lodash.reduce(this.order.facades,  (result, facade) => {
                if (facade.deleted) {
                    result.deleted++
                } else {
                    const square = Math.ceil(facade.height * facade.width / 1000) / 1000
                    result.amount += Math.ceil(square * facade.price * 100) / 100
                    result.quantity++
                    if (facade.stage.id === this.stage.id) {
                        result.current++
                    } else if (facade.stage.sort < this.stage.sort) {
                        result.before++
                    } else {
                        result.after++
                    }
                    if (currentFacadeStageChange.facadeId === facade.id
                        && currentFacadeStageChange.stageId === this.stage.id
                    ) {
                        result.inWork = true;
                    }
                }
                return result
            }, {amount: 0, quantity: 0, before: 0, current: 0, after: 0, deleted: 0, inWork: false})

            if (data.quantity) {
                data.title = data.after + ' готово / ' + data.current + ' в процессе / ' + data.before + ' ожидается'
                data.afterPercent = Math.ceil(data.after / data.quantity * 100)
                data.currentPercent = Math.ceil(data.current / data.quantity * 100)
                data.totalPercent = data.afterPercent + data.currentPercent
            }

            return data
        }
    },
}