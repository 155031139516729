<template>
    <div class="print-sticker" :class="{ pagebreakAfter: !(facade.facadeId % 5) }">
        <div class="print-sticker-left">
            <p><strong>Материал:</strong> {{ getPropertyLabel('material') }}</p>
            <p><strong>Тип фасада:</strong> {{ getPropertyLabel('type') }}</p>
            <p><strong>Фрезеровка:</strong> {{ facade.milling ? facade.milling : 'нет' }}</p>
            <p><strong>Фреза по краю:</strong> {{ getPropertyLabel('edgeMilling') }}</p>
            <p><strong>Тип:</strong> {{ getPropertyLabel('coatingType') }}</p>
            <p><strong>Цвет:</strong> {{ facade.color }}</p>
            <p><strong>Высота:</strong> {{ facade.height }} мм</p>
            <p><strong>Ширина:</strong> {{ facade.width }} мм</p>
        </div>
        <div class="print-sticker-right">
            <p>{{ facade.facadeId }}</p>
            <qrcode-vue :value="url" :size="80" level="H"/>
        </div>
    </div>
</template>
<script>
import FacadeDataMixin from "@/mixins/facadeDataMixin";
import QrcodeVue from 'qrcode.vue'

export default {
    name: 'PrintSticker',
    props: ['facade', 'orderId'],
    components: { QrcodeVue },
    mixins: [FacadeDataMixin],
    computed: {
        url() {
            return `${window.location.hostname}/orders/${this.orderId}?facade=${this.facade.facadeId}`
        },
    },
}
</script>