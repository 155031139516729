export default {
    data() {
        return {
            isLoading: false
        }
    },
    mounted: function () {
        this.$store.dispatch('order/setOrders').then(() => {
            this.isLoading = true
        })
    },
}