<template>
    <transition-group name="order-list">
        <ListItem
                v-for="(order, index) in stage.orders"
                :key="order.id"
                @click="showOrder(order.id)"
                :order="order"
                :stage="stage"
                :not-last="index < stage.orders.length - 1"
        />
    </transition-group>
</template>
<script>
import ListItem from "@/components/order/ListItem.vue";

export default {
    name: 'OrderList',
    components: { ListItem },
    props: ['stage'],
    methods: {
        showOrder: function (orderId) {
            this.$router.push({ name: 'order', params: { id: orderId }, query: { stage: this.stage.id } })
        }
    }
}
</script>
<style scoped>
.order-list-item {
    display: inline-block;
    margin-right: 10px;
}
.order-list-enter-active,
.order-list-leave-active {
    transition: all 1s ease;
}
.order-list-enter-from,
.order-list-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>