import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/ru';

export default {
    install: () => {
        dayjs.extend(updateLocale)
        dayjs.updateLocale('ru', {
            weekStart: 0,
        })
        dayjs.getWord = (number, words) => {
            number = number % 100;
            if (number >= 11 && number <= 19) {
                return words[2];
            }
            else {
                switch (number % 10)
                {
                    case (1): return words[0];
                    case (2):
                    case (3):
                    case (4): return words[1];
                }
            }
            return words[2];
        }
        dayjs.getHourWord = (number) => ' ' + dayjs.getWord(number, ['час', 'часа', 'часов'])
        dayjs.getMinuteWord = (number) => ' ' + dayjs.getWord(number, ['минута', 'минуты', 'минут'])
    }
}