import axios from 'axios'
import lodash from "lodash";
import router from "@/router";

export const orderModule = {
    state: () => ({
        orders: [],
        stages: [],
        currentFacadeStageChange: '',
    }),
    mutations: {
        setOrders(state, orders) {
            orders.forEach(order => order.facades && order.facades.forEach(((facade, index) => facade.facadeId = index + 1)))
            state.orders = orders
        },
        setOrder(state, order) {
            const orderKey = lodash.findKey(state.orders, { 'id': Number(order.id) })
            order.facades && order.facades.forEach(((facade, index) => facade.facadeId = index + 1))
            state.orders[orderKey] = order
        },
        setStages(state, stages) {
            state.stages = stages
        },
        setCurrentFacadeStageChange(state, currentFacadeStageChange) {
            state.currentFacadeStageChange = currentFacadeStageChange
        },
    },
    actions: {
        async setOrders({ commit, dispatch }, repeat = true) {
            await dispatch('setStages')
            await dispatch('setCurrentFacadeStageChange')
            const orders = await axios.get('/orders')
            commit('setOrders', orders.data)
            if (repeat) {
                setTimeout(() => {
                    if (router.currentRoute.value.name.startsWith('orders')) {
                        dispatch('setOrders')
                    }
                }, 5000)
            }
        },
        async setOrder({ commit, dispatch }, { id, repeat = true }) {
            await dispatch('setStages')
            await dispatch('setCurrentFacadeStageChange')
            if (id) {
                const order = await axios.get('/orders/' + id)
                commit('setOrder', order.data)
                if (repeat) {
                    setTimeout(() => {
                        if (
                            router.currentRoute.value.name.startsWith('order')
                            && Number(router.currentRoute.value.params.id) === id
                        ) {
                            dispatch('setOrder', { id })
                        }
                    }, 5000)
                }
            }
        },
        async setStages({ commit, getters }) {
            if (getters['getStages'].length === 0) {
                const stages = await axios.get('/stages')
                commit('setStages', stages.data)
            }
        },
        async setCurrentFacadeStageChange({ commit, rootGetters }) {
            if (rootGetters['user/isWorker']) {
                const currentFacadeStageChange = await axios.get('/facades/get-current-facade-stage-change')
                commit('setCurrentFacadeStageChange', currentFacadeStageChange.data)
            }
        },
    },
    getters: {
        getStagesWithOrders: (state, getters, rootState, rootGetters) => {
            const stagesWithOrders = lodash.reduce(state.orders, function (stages, order) {
                const deleteStage = lodash.find(stages, { 'slug': 'deleted' })
                if (order.facades.length === 0) {
                    stages[0].orders.push(order)
                } else {
                    order.facades.forEach(facade => {
                        const stage = facade.deleted
                            ? deleteStage
                            : lodash.find(stages, { 'slug': facade.stage.slug })
                        if (!lodash.find(stage.orders, { 'id': order.id })) {
                            stage.orders.push(order)
                        }
                    })
                }
                return stages
            }, state.stages.map(stage => {
                return { ...stage, orders: [] }
            }))
            return stagesWithOrders.filter(stage =>
                stage.orders.length > 0
                && (!rootGetters['user/isWorker'] || lodash.find(rootGetters['user/getUser'].stages, { 'id': stage.id }))
            )
        },
        getOrder: state => id => {
            return lodash.find(state.orders, { 'id': Number(id) })
        },
        getNextStage: (state, getters) => (orderId, stageId) => {
            const order = getters.getOrder(orderId)
            const currentStageIndex = lodash.findIndex(order.stages, { 'id': stageId })
            let nextStage = order.stages[currentStageIndex + 1]
            return nextStage ?? getters['getNonWorkingStages'][1]
        },
        getStages: state => {
            return state.stages
        },
        getWorkingStages: state => {
            return lodash.filter(state.stages, ['working', true])
        },
        getNonWorkingStages: state => {
            return lodash.filter(state.stages, ['working', false])
        },
        getCurrentFacadeStageChange: state => {
            return state.currentFacadeStageChange
        },
    },
    namespaced: true
}