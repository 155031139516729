<template>
    <a-badge-ribbon text="В работе" :class="{'hidden': !calculatedData.inWork}">
        <a-card
            :title="title"
            :key="order.id"
            :class="{'mb-15': notLast}"
            hoverable
        >
            <p>{{ client }}</p>
            <p>{{ deadline }}</p>
            <p v-if="calculatedData.quantity">Количество фасадов: {{ calculatedData.quantity }} шт.</p>
            <p v-if="calculatedData.deleted">Количество удаленных фасадов: {{ calculatedData.deleted }} шт.</p>
            <p v-if="userIsManager() && calculatedData.amount">Стоимость: {{ Math.ceil(calculatedData.amount * 100) / 100 }} руб.</p>
            <a-tooltip v-if="stage.working" :title="calculatedData.title">
                <a-progress
                    :percent="calculatedData.totalPercent"
                    :success="{ percent: calculatedData.afterPercent }"
                    :format="() => `${calculatedData.afterPercent}%`"
                    type="circle"
                />
            </a-tooltip>
        </a-card>
    </a-badge-ribbon>
</template>
<script>
import OrderItemMixin from "@/mixins/orderItemMixin";
import dayjs from "dayjs";
import UserMixin from "@/mixins/userMixin";

export default {
    name: 'OrderListItem',
    methods: {dayjs},
    props: ['order', 'stage', 'notLast'],
    mixins: [OrderItemMixin, UserMixin],
}
</script>
<style scoped>
.mb-15 {
    margin-bottom: 15px;
}
</style>