<template>
    <a-collapse v-model:activeKey="collapseActiveKey">
        <a-collapse-panel key="1" header="Исполнители">
            <table class="table">
                <thead>
                <tr>
                    <th>Стадия</th>
                    <th>Сотрудник</th>
                    <th>Начало</th>
                    <th>Окончание</th>
                    <th>Длительность</th>
                </tr>
                </thead>
                <tbody>
                <StageChangeItem v-for="stageChange in stageChanges" :stageChange="stageChange" />
                </tbody>
            </table>
        </a-collapse-panel>
    </a-collapse>
</template>
<script>
import StageChangeItem from "@/components/order/facade/stageChange/Item.vue";

export default {
    name: 'WorkerList',
    components: {StageChangeItem},
    props: ['stageChanges'],
    data() {
        return {
            collapseActiveKey: [],
        }
    },
}
</script>