<template>
    <Header />
    <div v-if="isLoading">
        <OrderItem />
    </div>
    <a-row v-else type="flex" justify="space-around" align="middle" class="spin">
        <a-spin size="large" />
    </a-row>
</template>
<script>
import orderMixin from "@/mixins/orderMixin";
import OrderItem from "@/components/order/Item.vue";
import Header from "@/components/Header.vue";

export default {
    name: 'OrderView',
    components: { Header, OrderItem },
    mixins: [orderMixin],
}
</script>