import axios from 'axios'
import lodash from "lodash";

export const userModule = {
    state: () => ({
        user: {}
    }),
    mutations: {
        setUser(state, user) {
            state.user = user;
        }
    },
    actions: {
        async setUser({ app, state, commit }) {
            if (lodash.isEmpty(state.user)) {
                const user = await axios.get('/auth/get-user')
                commit('setUser', user.data)
            }
        }
    },
    getters: {
        getUser: state => {
            return state.user
        },
        isManager: state => {
            return state.user && lodash.find(state.user.roles, {'slug': 'manager'})
        },
        isWorker: state => {
            return state.user && lodash.find(state.user.roles, {'slug': 'worker'})
        },
        canTakeToWork: (state, getters, rootState, rootGetters) => stageId => {
            return getters.isWorker
                && getters.hasStage(stageId)
                && !rootGetters['order/getCurrentFacadeStageChange']
        },
        hasStage: (state) => stageId => {
            return lodash.find(state.user.stages, {'id': stageId})
        },
    },
    namespaced: true
}