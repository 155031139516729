import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
import AxiosPlugin from './plugins/axios'
import DayjsPlugin from './plugins/dayjs'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';

createApp(App)
    .use(VueAxios, axios)
    .use(AxiosPlugin)
    .use(DayjsPlugin)
    .use(Antd)
    .use(store)
    .use(router)
    .mount('#app')
