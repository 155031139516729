export default {
    methods: {
        userIsManager() {
            return this.$store.getters['user/isManager']
        },
        userIsWorker() {
            return this.$store.getters['user/isWorker']
        },
        userCanTakeToWork(stageId) {
            return this.$store.getters['user/canTakeToWork'](stageId)
        },
    }
}