<template>
    <a-modal
        v-model:visible="visible"
        title="Добавить фасад"
        :confirm-loading="confirmLoading"
        ok-text="Сохранить"
        cancel-text="Отменить"
        @ok="clickSave"
        width="90%"
        style="max-width: 900px"
    >
        <a-form
            :model="formState"
            name="create-facade"
            layout="horizontal"
            ref="formRef"
        >
            <a-row type="flex" justify="space-around">
                <a-col>
                    <a-form-item
                        label="Материал"
                        name="materialId"
                        :rules="[{ required: true, message: 'Выберите материал!' }]"
                    >
                        <a-select
                            v-model:value="formState.materialId"
                            placeholder="Выберите материал"
                            style="width: 150px"
                            :options="facadeData.materials"
                        ></a-select>
                    </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item
                        label="Тип"
                        name="typeId"
                        :rules="[{ required: true, message: 'Выберите тип фасада!' }]"
                    >
                        <a-select
                            v-model:value="formState.typeId"
                            placeholder="Выберите тип фасада"
                            style="width: 150px"
                            :options="facadeData.types"
                        ></a-select>
                    </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item
                        label="Фрезеровка"
                        name="milling"
                    >
                        <a-input
                            v-model:value="formState.milling"
                            style="width: 120px"
                            placeholder="Выберите фрезеровку"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="space-around">
                <a-col>
                    <a-form-item
                        label="Фреза по краю"
                        name="edgeMillingId"
                        :rules="[{ required: true, message: 'Выберите фрезу по краю!' }]"
                    >
                        <a-select
                            v-model:value="formState.edgeMillingId"
                            placeholder="Выберите фрезу по краю"
                            style="width: 110px"
                            :options="facadeData.edgeMillings"
                        ></a-select>
                    </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item
                        label="Тип покрытия"
                        name="coatingTypeId"
                        :rules="[{ required: true, message: 'Выберите тип покрытия!' }]"
                    >
                        <a-select
                            v-model:value="formState.coatingTypeId"
                            placeholder="Выберите тип покрытия"
                            style="width: 230px"
                            :options="facadeData.coatingTypes"
                        ></a-select>
                    </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item
                        label="Цвет"
                        name="color"
                        :rules="[{ required: true, message: 'Введите цвет!' }]"
                    >
                        <a-input
                            v-model:value="formState.color"
                            style="width: 150px"
                            placeholder="Введите цвет"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="space-around">
                <a-col>
                    <a-form-item
                        label="Высота, мм"
                        name="height"
                        :rules="[{ required: true, message: 'Введите высоту!' }]"
                    >
                        <a-input-number v-model:value="formState.height" :min="10"/>
                    </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item
                        label="Ширина, мм"
                        name="width"
                        :rules="[{ required: true, message: 'Введите ширину!' }]"
                    >
                        <a-input-number v-model:value="formState.width" :min="10"/>
                    </a-form-item>
                </a-col>
                <a-col>
                    <a-form-item
                        label="Цена"
                        name="price"
                        :rules="[{ required: true, message: 'Введите цену!' }]"
                    >
                        <a-input-number
                            v-model:value="formState.price"
                            style="width: 150px"
                            placeholder="Введите цену"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="space-around">
                <a-col>
                    <a-form-item
                        label="Примечание"
                        name="note"
                    >
                        <a-textarea
                            v-model:value="formState.note"
                            :rows="4"
                            style="width: 700px"
                            placeholder="Примечание"
                        />
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-modal>
</template>
<script>
import FacadeDataMixin from "@/mixins/facadeDataMixin";
import lodash from "lodash";

export default {
    name: 'FacadeForm',
    props: ['orderId'],
    mixins: [FacadeDataMixin],
    data() {
        return {
            visible: false,
            confirmLoading: false,
            formState: {
                materialId: null,
                typeId: null,
                height: null,
                width: null,
                milling: null,
                edgeMillingId: null,
                coatingTypeId: null,
                color: null,
                price: null,
                note: null,
            },
            facadeId: null,
        }
    },
    methods: {
        open(facade, edit = false) {
            this.visible = true
            if (facade) {
                if (edit) {
                    this.facadeId = facade.id
                } else {
                    this.facadeId = null
                }
                lodash.keys(this.formState).forEach(key => {
                    this.formState[key] = facade[key]
                })
            } else {
                lodash.keys(this.formState).forEach(key => {
                    this.formState[key] = null
                })
                this.facadeId = null
            }
        },
        clickSave() {
            this.confirmLoading = true
            this.$refs.formRef.validateFields()
                .then(() => {
                    if (this.facadeId) {
                        this.updateFacade()
                    } else {
                        this.saveFacade()
                    }
                })
                .catch(() => this.confirmLoading = false)
        },
        saveFacade: async function () {
            const facade = await this.axios.post(
                '/facades/create',
                {orderId: this.orderId, ...this.formState}
            )
            if (facade.data.id) {
                this.afterSuccessfulRequest()
            }
        },
        updateFacade: async function () {
            const result = await this.axios.post(
                '/facades/update',
                {
                    id: this.facadeId,
                    orderId: this.orderId,
                    ...this.formState
                }
            )
            if (result.data.length) {
                this.afterSuccessfulRequest()
            }
        },
        afterSuccessfulRequest() {
            this.$store.dispatch('order/setOrder', { id: this.orderId, repeat: false })
            this.visible = false
            this.confirmLoading = false
        }
    },
}
</script>