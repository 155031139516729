import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'
import LoginView from "@/views/LoginView.vue";
import StagesView from "@/views/StagesView.vue";
import OrderView from "@/views/OrderView.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/orders',
        name: 'orders',
        component: StagesView
    },
    {
        path: '/orders/:id',
        name: 'order',
        component: OrderView
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from) => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
        if (to.name === 'login') {
            return { name: 'home' }
        }
        await store.dispatch('user/setUser')
    } else {
        if (to.name !== 'login') {
            return { name: 'login', query: { redirect: to.path } }
        }
    }
})

export default router
