import lodash from "lodash";
export default {
    methods: {
        getPropertyLabel(property) {
            const item = lodash.find(this.facadeData[property + 's'], {'value': this.facade[property + 'Id']})
            return item ? item.label : ''
        },
    },
    computed: {
        facadeData() {
            return this.$store.getters['facadeData/getFacadeData']
        },
        square() {
            return Math.ceil(this.facade.height * this.facade.width / 1000) / 1000
        }
    },
}