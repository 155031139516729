<template>
    <tr>
        <td><span>Стадия</span>{{ stageChange.stage.name }}</td>
        <td><span>Сотрудник</span>{{ stageChange.user.name }} {{ stageChange.user.secondName }}</td>
        <td><span>Начало</span>
            <strong>{{ createdAt.format('HH:mm ') }}</strong>
            {{ createdAt.format('DD-MM-YYYY') }}
        </td>
        <td><span>Окончание</span>
            <template v-if="closedAt">
                <strong>{{ closedAt.format('HH:mm ') }}</strong>
                {{ closedAt.format('DD-MM-YYYY') }}
            </template>
        </td>
        <td><span>Длительность</span>{{ difference }}</td>
    </tr>
</template>
<script>
import dayjs from "dayjs";
export default {
    name: 'stageChangeItem',
    props: ['stageChange'],
    computed: {
        createdAt() {
            return dayjs(this.stageChange.createdAt)
        },
        closedAt() {
            return this.stageChange.closedAt ? dayjs(this.stageChange.closedAt) : null
        },
        difference() {
            if (!this.closedAt) {
                return ''
            }
            const totalMinutes = this.closedAt.diff(this.createdAt, 'minute')
            const minutes = totalMinutes % 60
            const hours = (totalMinutes - minutes) / 60
            return (hours ? hours + dayjs.getHourWord(hours) + ' ' : '') +
                + minutes + dayjs.getMinuteWord(minutes)
        }
    }
}
</script>