export default {
    install: (app) => {
        app.axios.defaults.baseURL = process.env.VUE_APP_BACK_URL
        app.axios.interceptors.request.use(function (config) {
            const accessToken = localStorage.getItem('accessToken')
            if (accessToken) {
                config.headers.Authorization = 'Bearer ' + accessToken;
            }
            return config;
        });
        app.axios.interceptors.response.use(function (response) {
            return response;
        }, async function (error) {
            if (error.response.status === 401) {
                if (error.config.url === '/auth/refresh-tokens/') {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    //redirect(302, '/login')
                }
                const localRefreshToken = localStorage.getItem('refreshToken')
                if (localRefreshToken) {
                    try {
                        const newTokens = await app.axios.post('/auth/refresh-tokens/', {
                            refreshToken: localRefreshToken
                        })
                        const { accessToken, refreshToken } = newTokens.data;
                        if (accessToken && refreshToken) {
                            localStorage.setItem('accessToken', accessToken)
                            localStorage.setItem('refreshToken', refreshToken)
                            error.config.headers['Authorization'] = 'Bearer ' + accessToken
                            return app.axios(error.config)
                        }
                    } catch (error) {
                        return Promise.resolve(false);
                    }
                }
            }
        })
    }
}