<template>
    <div ref="print" class="print-order">
        <header>
            <div class="print--title">{{ title }}</div>
            <div>{{ client }}</div>
            <div>{{ deadline }}</div>
            <div>Всего фасадов: {{ order.facades.length }}</div>
        </header>
        <div class="print--qrcode">
            <qrcode-vue :value="url" :size="100" level="H" />
        </div>
        <table v-if="order.facades.length">
            <thead>
            <tr>
                <th>№</th>
                <th>Фасад</th>
                <th>Обработка</th>
                <th>Покрытие</th>
                <th>Размеры</th>
                <th>м<sup>2</sup></th>
                <th>Кол.</th>
                <th>Примечание</th>
            </tr>
            </thead>
            <tr v-for="({facade, numbers}) in processedFacades">
                <FacadePrint
                    :facade="facade"
                    :numbers="numbers"
                />
            </tr>
        </table>
    </div>
</template>
<script>
import FacadePrint from "@/components/order/facade/Print.vue";
import OrderItemMixin from "@/mixins/orderItemMixin";
import lodash from "lodash";
import html2pdf from "html2pdf.js";
import QrcodeVue from 'qrcode.vue'

export default {
    name: 'OrderPrint',
    props: ['order'],
    components: { FacadePrint, QrcodeVue },
    mixins: [OrderItemMixin],
    computed: {
        processedFacades() {
            return lodash.reduce(this.order.facades, (facades, facade, index) => {
                const pickFields = [
                    'materialId',
                    'typeId',
                    'height',
                    'width',
                    'milling',
                    'edgeMillingId',
                    'coatingTypeId',
                    'color',
                    'note',
                ]
                const pickFacade = lodash.pick(facade, pickFields)
                for (const facadeWithData of facades) {
                    if (lodash.isEqual(facadeWithData.facade, pickFacade)) {
                        facadeWithData.numbers.push(index + 1)
                        return facades
                    }
                }
                facades.push({ numbers: [index + 1], facade: pickFacade })
                return facades
            }, [])
        },
        url() {
            return `${window.location.hostname}/orders/${this.order.id}`
        },
    },
    methods: {
        print() {
            html2pdf(this.$refs.print, {
                margin: [1, 10, 1, 10],
                filename: `order-${this.order.id}.pdf`,
                html2canvas:  { scale: 4 },
                jsPDF: { orientation: 'landscape'},
            });
        },
    },
}
</script>
<style scoped>

</style>