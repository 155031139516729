<template>
    <div class="login">
        <a-row type="flex" justify="space-around" align="middle" class="login-row">
            <a-form
                :model="formState"
                name="login"
                class="login-form"
                layout="vertical"
                @finish="onFinish"
            >
                <a-form-item
                    label="Логин"
                    name="login"
                    :rules="[{ required: true, message: 'Введите логин!' }]"
                >
                    <a-input v-model:value="formState.login">
                        <template #prefix>
                            <UserOutlined class="site-form-item-icon"/>
                        </template>
                    </a-input>
                </a-form-item>
                <a-form-item
                    label="Пароль"
                    name="password"
                    :rules="[{ required: true, message: 'Введите пароль!' }]"
                >
                    <a-input-password v-model:value="formState.password">
                        <template #prefix>
                            <LockOutlined class="site-form-item-icon"/>
                        </template>
                    </a-input-password>
                </a-form-item>
                <a-form-item name="remember">
                    <a-checkbox v-model:checked="formState.remember">Запомнить меня</a-checkbox>
                </a-form-item>
                <a-form-item>
                    <a-button :disabled="disabled" type="primary" html-type="submit" class="login-form-button">
                        Войти
                    </a-button>
                </a-form-item>
            </a-form>
        </a-row>
    </div>
</template>

<script>
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";

export default {
    name: 'LoginView',
    components: {
        UserOutlined,
        LockOutlined,
    },
    data() {
        return {
            formState: {
                login: '',
                password: '',
                remember: true,
            },
        }
    },
    computed: {
        disabled() {
            return !(this.formState.login && this.formState.password);
        },
    },
    methods: {
        onFinish: function () {
            this.axios.post('/auth/login/', {
                login: this.formState.login,
                password: this.formState.password
            }).then((response) => {
                const { accessToken, refreshToken, user } = response.data;
                localStorage.setItem('accessToken', accessToken)
                if (this.formState.remember) {
                    localStorage.setItem('refreshToken', refreshToken)
                }
                this.$store.commit('user/setUser', user)
                this.$router.replace(this.$route.query.redirect || '/orders')
            }).catch(() => {
                message.error('Ошибка авторизации');
            })
        },
    },
}
</script>
<style scoped>
.login {
    position: fixed;
    width: 100%;
    height: 100%;
}
.login-row {
    display: flex;
    height: 100%;
    justify-content: center;
}
.login-form {
    max-width: 300px;
}
.login-form-button {
    width: 100%;
}
</style>