<template>
    <a-page-header
        class="header"
        @back="() => $router.go(-1)"
    >
        <template #extra>
            <a-button
                @click="$router.push('/orders')"
            >
                Список заказов
            </a-button>
            <a-button
                type="primary"
                v-if="userIsManager()"
                @click="$refs.OrderForm.open()"
            >
                Создать заказ
            </a-button>
            <a-tooltip title="Выйти" :color="'#108ee9'">
                <a-button shape="circle" @click="logOut">
                    <template #icon>
                        <LoginOutlined/>
                    </template>
                </a-button>
            </a-tooltip>
        </template>
    </a-page-header>
    <OrderForm ref="OrderForm" />
</template>
<script>
import { LoginOutlined } from '@ant-design/icons-vue';
import UserMixin from "@/mixins/userMixin";
import OrderForm from "@/components/order/Form.vue";

export default {
    name: 'Header',
    components: { OrderForm, LoginOutlined },
    mixins: [UserMixin],
    methods: {
        logOut() {
            this.$router.push('/login')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            this.$store.commit('order/setOrders', null)
            this.$store.commit('user/setUser', null)
        },
    }
}
</script>
<style scoped>
.header {
    box-shadow: 0 .1rem 1rem .25rem #0000000d;
}
</style>