<template>
    <router-view/>
</template>
<style>
.hidden {
    display: none;
}
.center {
    text-align: center;
}
.relative {
    position: relative;
}
.ant-row {
    margin-bottom: 15px;
}
.ant-picker-date-panel {
    width: 100% !important;
}
.ant-progress-circle {
    display: flex !important;
}
.ant-progress-circle > div {
    margin: 0 auto;
}
span.ant-badge-status-dot {
    width: 10px;
    height: 10px;
}

.ant-card-bordered.show-all {
    margin-left: auto;
    margin-top: auto;
}

.ant-card-bordered.show-all .ant-card-body {
    padding: 14px;
}

.table {
    border-radius: 5px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
}

.table th {
    padding: 10px 20px;
    font-size: 13px;
    font-family: Verdana, sans-serif;
    border: 1px solid #f0f0f0;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.85);
    background: #fafafa;
    font-weight: normal;
    text-align: center;
}

.table tr.blur {
    color: rgba(0, 0, 0, 0.45);
}

.table tr.marked {
    background-color: #f5f5f5;
}

.table td {
    padding: 10px 20px;
    font-size: 13px;
    font-family: Verdana, sans-serif;
    border: 1px solid #f0f0f0;
    vertical-align: top;
}

.table td > span {
    display: none;
}

.table tr:nth-child(even) {
    /*background: #edf7ff;*/
}

@media (max-width: 992px) {
    .ant-card-head, .ant-card {
        font-size: 17px !important;
    }

    .ant-card-bordered.show-all {
        width: 100%;
        margin-bottom: 15px;
    }

    .show-all .ant-card-body {
        padding: 10px;
    }

    .show-all button {
        margin-right: 15px;
    }

    .table thead {
        display: none;
    }

    .table tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }

    .table td {
        margin: 0 -1px -1px 0;
        padding-top: 35px;
        position: relative;
        width: 50%;
        font-size: 17px;
    }

    .table td .number {
        font-size: 34px;
        font-weight: 700;
        margin-top: -15px;
    }

    .table .stage {
        font-weight: 700;
    }

    .table td > span {
        display: block;
        background: #00a0e9;
        color: #FFF;
        font-size: 14px;
        font-weight: bold;
        font-family: Verdana, sans-serif;
        padding: 5px 10px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 15px;
    }

    .table td .in-work {
        right: initial;
        left: 10px;
    }

    .ant-btn-primary.green, .ant-btn-primary.green:hover {
        border-color: #52c41a;
        background: #52c41a;
    }
}

@media (max-width: 480px) {
    .table td {
        width: 100% !important;
    }
}

.print-order {
    color: rgba(0, 0, 0, 1);
}

.print-order header {
    width: 80%;
    display: inline-block;
    float: left;
    padding: 30px 0 0 20px;
    font-size: 16px;
}

.print--title {
    width: 100%;
    padding-left: 80px;
    text-align: center;
    font-size: 22px;
}

.print--qrcode {
    display: inline-block;
    padding: 20px 0 20px 40px;
}

.print--node {
    white-space: pre-line;
    max-width: 200px;
}

.print-order table {
    width: 100%;
}

.print-order thead tr {
    border-top: 1px solid #8a8585;
}

.print-order table tr {
    border-bottom: 1px solid #8a8585;
}

.print-order table th {
    padding: 2px 7px;
    font-size: 12px;
    vertical-align: top;
    font-weight: 600;
    text-align: center;
    border-left: 1px solid #8a8585;
}

.print-order table th:last-child {
    border-right: 1px solid #8a8585;
}

.print-order table td {
    padding: 2px 7px;
    font-size: 12px;
    vertical-align: top;
    border-left: 1px solid #8a8585;
}

.print-order table td:last-child {
    border-right: 1px solid #8a8585;
}

.print-sticker {
    border-bottom: 1px solid #8a8585;
    padding: 10px;
    width: 45%;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    height: 100%;
}

.print-sticker-left {
    width: 70%;
}

.print-sticker-left p {
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.print-sticker-right {
    width: 30%;
    text-align: center;
}

.print-sticker-right p {
    font-size: 35px;
    margin-bottom: 10px;
}
</style>