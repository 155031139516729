<template>
    <a-modal
        v-model:visible="visible"
        :title="title"
        :confirm-loading="confirmLoading"
        ok-text="Сохранить"
        cancel-text="Отменить"
        @ok="clickSave"
        width="70%"
    >
        <a-form
            :model="formState"
            name="form-client"
            layout="horizontal"
            ref="formRef"
        >
            <a-form-item
                label="Наименование"
                name="client"
                :rules="[{ required: true, message: 'Введите название заказчика!' }]"
            >
                <a-input v-model:value="formState.client"/>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import {UserOutlined} from "@ant-design/icons-vue";

export default {
    name: 'ClientForm',
    components: {UserOutlined},
    data() {
        return {
            visible: false,
            confirmLoading: false,
            title: '',
            formState: {},
            client: {}
        }
    },
    methods: {
        open(client) {
            this.client = client
            if (this.client) {
                this.title = 'Отредактировать заказчика'
                this.formState.client = this.client.label
            } else {
                this.title = 'Создать заказчика'
                this.formState.client = ''
            }
            this.visible = true
        },
        clickSave() {
            this.confirmLoading = true
            this.$refs.formRef.validateFields()
                .then(() => {
                    if (this.client) {
                        this.updateClient()
                    } else {
                        this.createClient()
                    }
                })
                .catch(() => this.confirmLoading = false)
        },
        updateClient: async function () {
            const result = await this.axios.post('/clients/update', {
                id: this.client.value,
                name: this.formState.client,
            })
            if (result) {
                this.visible = false
                this.confirmLoading = false
                this.$emit('update', {
                    value: this.client.value,
                    label: this.formState.client,
                })
            }
        },
        createClient: async function () {
            const client = await this.axios.post('/clients/create', {
                name: this.formState.client,
            })
            if (client.data.id) {
                this.visible = false
                this.confirmLoading = false
                this.$emit('create', {
                    value: client.data.id,
                    label: client.data.name,
                })
            }
        }
    },
}
</script>