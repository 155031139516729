import { createStore } from 'vuex'
import { userModule } from "@/store/userModule";
import { orderModule } from "@/store/orderModule";
import { facadeDataModule } from "@/store/facadeDataModule";

export default createStore({
    modules: {
        user: userModule,
        order: orderModule,
        facadeData: facadeDataModule,
    }
})
